/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Image, Text, ColumnCover, ColumnDiv } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="mt--40 pb--80 pt--0" name={"introduction"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--1 pb--0 pt--0" anim={"2"} animS={"4"} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"Professional freelance photographer.<br>15 years of experience — won <span style=\\font-style: italic;\\\">2x Cannes Jaguar</span>, <span style=\\\"\"font-style: italic;\\\"\">5x IRA Photo Awards</span>. Specializing in portraits and events."}>
              </Title>

              <Subtitle className="subtitle-box" content={"<a href=\"#contact\">Get in touch</a>"}>
              </Subtitle>

              <Image className="--left pl--06" alt={""} src={"https://cdn.swbpg.com/o/11591/9768aab0a86f4447a167317692ed3a50.svg"} svg={false} href={"/en/contact"} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" anim={null} name={"photogallery"} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3 pl--0 pr--0" anim={null} columns={"3"}>
            
            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/445337bc083c430f9a26500b2ebc47c7_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/445337bc083c430f9a26500b2ebc47c7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/445337bc083c430f9a26500b2ebc47c7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/445337bc083c430f9a26500b2ebc47c7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/445337bc083c430f9a26500b2ebc47c7_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/28f0cb5bb5d24738b12a581009f15f68_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/28f0cb5bb5d24738b12a581009f15f68_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/28f0cb5bb5d24738b12a581009f15f68_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/28f0cb5bb5d24738b12a581009f15f68_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/28f0cb5bb5d24738b12a581009f15f68_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11591/28f0cb5bb5d24738b12a581009f15f68_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/9f2453e7fd86490a8de05043c0663618_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/9f2453e7fd86490a8de05043c0663618_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/9f2453e7fd86490a8de05043c0663618_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/9f2453e7fd86490a8de05043c0663618_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/9f2453e7fd86490a8de05043c0663618_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11591/9f2453e7fd86490a8de05043c0663618_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/c1ba09fcac6c49afa6c62b1b60228cb1_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/c1ba09fcac6c49afa6c62b1b60228cb1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/c1ba09fcac6c49afa6c62b1b60228cb1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/c1ba09fcac6c49afa6c62b1b60228cb1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/c1ba09fcac6c49afa6c62b1b60228cb1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11591/c1ba09fcac6c49afa6c62b1b60228cb1_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/6e9f7b9238bc47d8aa86399127fd879e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/6e9f7b9238bc47d8aa86399127fd879e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/6e9f7b9238bc47d8aa86399127fd879e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/6e9f7b9238bc47d8aa86399127fd879e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/6e9f7b9238bc47d8aa86399127fd879e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11591/6e9f7b9238bc47d8aa86399127fd879e_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/8fd9838b510247f38c92785a85532917_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/8fd9838b510247f38c92785a85532917_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/8fd9838b510247f38c92785a85532917_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/8fd9838b510247f38c92785a85532917_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/8fd9838b510247f38c92785a85532917_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--06 pr--06">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/154da4687f6b43ee907b73bb4ee35bfa_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/154da4687f6b43ee907b73bb4ee35bfa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/154da4687f6b43ee907b73bb4ee35bfa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/154da4687f6b43ee907b73bb4ee35bfa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/154da4687f6b43ee907b73bb4ee35bfa_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/141b065176214867ab6ee50631170e82_s=860x_.jpg"} svg={false} ratio={null} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/141b065176214867ab6ee50631170e82_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/141b065176214867ab6ee50631170e82_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/141b065176214867ab6ee50631170e82_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/141b065176214867ab6ee50631170e82_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11591/141b065176214867ab6ee50631170e82_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/ffb84bd79d444e6e8bb7ba32820cde94_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11591/ffb84bd79d444e6e8bb7ba32820cde94_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/ffb84bd79d444e6e8bb7ba32820cde94_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/ffb84bd79d444e6e8bb7ba32820cde94_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/ffb84bd79d444e6e8bb7ba32820cde94_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11591/ffb84bd79d444e6e8bb7ba32820cde94_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim5 --anim-s4 --center pb--40 pt--10" anim={"5"} name={"photogallery-2"} animS={"4"}>
          
          <ColumnWrap className="column__flex --right el--1 pb--10 pl--0 pr--0 pt--10" anim={null} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":240}}>
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<a href=\"/photogallery\">More photos</a>"}>
              </Subtitle>

              <Image className="--left pl--06" alt={""} src={"https://cdn.swbpg.com/o/11591/9768aab0a86f4447a167317692ed3a50.svg"} svg={false} href={"/en/photogallery"} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"information"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--1 pb--30 pt--25" anim={null} style={{"maxWidth":1410}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 pb--0 pt--0" anim={"2"} animS={"4"} style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1118}} content={"<span style='color: var(--color-dominant)'>I provide a variety of photographic services — from product photography to events and dramatic portraits</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":682}} content={"<span style='color: var(--color-dominant)'>Click and start writing. This is a text area. If needed, the color, font size, line spacing, etc. can be customized.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"information-2"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 pl--06 pr--06 flex--center" anim={null} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/9fd0a4f4ce4a400ca9571d185b410889_s=860x_.png"} svg={false} href={""} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/11591/9fd0a4f4ce4a400ca9571d185b410889_s=350x_.png 350w, https://cdn.swbpg.com/t/11591/9fd0a4f4ce4a400ca9571d185b410889_s=660x_.png 660w, https://cdn.swbpg.com/t/11591/9fd0a4f4ce4a400ca9571d185b410889_s=860x_.png 860w, https://cdn.swbpg.com/t/11591/9fd0a4f4ce4a400ca9571d185b410889_s=1400x_.png 1400w"} content={null} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--36" content={"<span style='color: white'><a href=\"/photogallery\">Portraits</a></span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/4fa8ec6b4aaf4d10aac09ae3295fa3fe_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/11591/4fa8ec6b4aaf4d10aac09ae3295fa3fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/4fa8ec6b4aaf4d10aac09ae3295fa3fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/4fa8ec6b4aaf4d10aac09ae3295fa3fe_s=860x_.jpg 860w"} content={null} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--36" content={"<span style='color: white'><a href=\"/photogallery\">Product photography</a><br></span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr5">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11591/594ea2c1f3eb4bf7a5242593f67b2b4f_s=860x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/11591/594ea2c1f3eb4bf7a5242593f67b2b4f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11591/594ea2c1f3eb4bf7a5242593f67b2b4f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11591/594ea2c1f3eb4bf7a5242593f67b2b4f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11591/594ea2c1f3eb4bf7a5242593f67b2b4f_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--36" content={"<span style='color: white'><a href=\"/photogallery\">Events</a></span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" anim={null} name={"contact"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 el--2 pb--30 pt--25 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1410}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Title className="title-box pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Call me ...</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1150}}>
              
              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'>Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area.</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":590}} content={"<span style='color: var(--color-dominant)'><a href=\"https://saywebpage.com\">Create your own website</a></span>"}>
              </Text>

              <Subtitle className="subtitle-box lh--16 mb--40" content={"<span style='color: var(--color-dominant)'>Peter Domanicky<br>510-851-40X4<br>info@vase-stranky.com</span>"}>
              </Subtitle>

              <Image className="--left pb--06 pl--06 pr--06 pt--06" alt={""} src={"https://cdn.swbpg.com/o/11591/cac5c902f16a47b7b7aea31fabd7fc79.svg"} svg={false} href={"/en/contact"} sizes={"100vw"} style={{"maxWidth":210}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}